<script>
import { mapState } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
   components: {
      AppTimeline,
      AppTimelineItem,
   },
   data() {
      return {
      }
   },
   computed: {
      ...mapState({
         myProses: state => state.SicantikPermohonan.itemsProses,
      }),
      isLoading() {
         return this.$store.state.isLoading
      },
      getVariant() {
         return status => {
            let value = ''

            if (status === 'Selesai') {
               value = 'success'
            } else {
               value = 'danger'
            }

            return value
         }
      },
   },
   watch: {
      isLoading(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
      myProses(items) {
         return items.map(item => {
            console.log(item)
            return {
               nama_proses: item.nama_proses,
               diproses_by: `Diproses Oleh : ${item.diproses_by}`,
               end_date: item.end_date,
               id: item.id,
            }
         })
      },
   },
   methods: {

   },
}
</script>

<template>
   <app-timeline class="mt-2 mb-2">
      <app-timeline-item v-for="item in myProses" :key="item.id" :variant="getVariant(item.status)">
         <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
            <h5>
               <b>{{ item.nama_proses }}</b>
            </h5>

            <!-- <small class="timeline-item-time text-nowrap text-muted ml-1">{{ item.end_date | moment("DD-MM-YYYY HH:mm:ss") }}</small> -->
         </div>
         <small>
            Diproses Oleh
            <b>{{ item.diproses_by || '-' }}</b>
         </small>
         <br>
         <small>{{ item.end_date | moment("HH:mm:ss | DD MMMM YYYY ") }}</small>
      </app-timeline-item>
   </app-timeline>
</template>

<style>
.hidden_header {
   display: none;
}
</style>
